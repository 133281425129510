import Error from "../../components/Error/error";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHandPointDown, faHandPointUp} from "@fortawesome/free-solid-svg-icons";
import Rate from "../../components/Rate/rate";
import {faStar, faDollarSign} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/breadcrumb/breadcrumbSlice";
import { setShowMenu } from "../../store/header/header";
import { useSelector } from "react-redux";
const { useState } = require("react");
const { getSpot, getImages, getAverageRate } = require("../../services/api");


function Spot() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state)=>state.auth.isLoggedIn);
    const params = new URLSearchParams(window.location.search);
    const pricePerPerson = params.get('pricePerPerson');
    const diffDays = params.get('diffDays');
    const [spot, setSpot] = useState({});
    const [images, setImages] = useState([]);
    const [decodedDatas, setDecodedDatas] = useState({
        title: "",
        description: "",
        location: "",
        beds: "",
        link: "",
    });
    const spotId = useParams().spotId;
    const eventId = useParams().eventId;
    const collapsesDatas = [
        ["Description", decodedDatas.description],
        ["Localisation", decodedDatas.location],
        ["Couchages", decodedDatas.beds],
        ["Lien de l'annonce", decodedDatas.link],
    ];
    const [showError, setShowError] = useState(null);
    const [creatorName, setCreatorName] = useState("");
    const [rate, setRate] = useState(null);
    const [averageRate, setAverageRate] = useState();

    useEffect(()=>{
        if (!isLoggedIn) {
            navigate('/login');
        }
        dispatch(setBreadcrumb({name: "Revenir à l'évènement", navigateTo: `/events/${eventId}`}))
        dispatch(setShowMenu(false));
    })

    const toggleError = () => {
        setShowError(null);
    }
    useEffect(() => {
        async function getOneSpot() {
            try {
                const spotDatas = await getSpot(eventId, spotId);
                setSpot(spotDatas.spot);
                const decodedTitle = new DOMParser().parseFromString(spot.title_spot, "text/html").body.textContent;
                const decodedDescription = new DOMParser().parseFromString(spot.description, "text/html").body.textContent;
                const decodedLocation = new DOMParser().parseFromString(spot.location, "text/html").body.textContent;
                const decodedBeds = new DOMParser().parseFromString(spot.beds, "text/html").body.textContent;
                const decodedLink = new DOMParser().parseFromString(spot.link, "text/html").body.textContent;
                setDecodedDatas({
                    title: decodedTitle,
                    description: decodedDescription,
                    location: decodedLocation,
                    beds: decodedBeds,
                    link: decodedLink,
                })
                const creator = spotDatas.userCreatorName;
                setCreatorName(creator);
                const imagesDatas = await getImages(eventId, spotId);
                setImages(imagesDatas);
                const rateAverage = await getAverageRate(eventId, spotId);               
                setAverageRate(rateAverage);
                if (spotDatas.rate) {
                    setRate(spotDatas.rate);
                }
                
            } catch (err) {
                console.log(err);
                setShowError(err);
            }
        }
        getOneSpot()
    }, [eventId, spotId, spot.title_spot, spot.beds, spot.location, spot.description, spot.link])
    
    if (showError !=null ) return <Error errorData={showError} toggleError={toggleError} />
    return (
        <div className="spot">
            <h1 className="spot__title">{decodedDatas.title}</h1>
            <div className="spot__header">
                {spot.price!=="null" ? (
                    <div className="spot__prices">
                        <div className="spot__prices__icon">
                            <FontAwesomeIcon icon={faDollarSign} />
                        </div>
                        <div className="spot__prices__values">
                            <div className="spot__prices__values__total">
                                {spot.price} €/ {diffDays} jours
                            </div>
                            <div className="spot__prices__values__perPerson">
                                {pricePerPerson} €/ personne 
                            </div>
                        </div>
                    </div>
                    
                ):(
                    <div className="spot__indisponible">
                        Lieu indisponible aux dates
                    </div>
                )}
                
                <div className="spot__averageRate">
                    <div className="spot__averageRate__label">
                        Note moyenne
                    </div>
                    <div className="spot__averageRate__content">
                        <div className="spot__averageRate__content__icon">
                            <FontAwesomeIcon icon={faStar} />
                        </div>
                        <div className="spot__averageRate__content__value">
                            {averageRate}/10
                        </div>
                    </div>
                </div>
            </div>
            <div className="spot__user">
                proposé par {creatorName}
            </div>
            <div className="spot__images">
                {images.map((image)=>(
                    <div className="spot__images__image" key={image.url}>
                        <img src={image.url} alt="lieu" />
                    </div>
                ))}
            </div>
            <Rate className="spot__rate" spotId={spotId} rate={rate} />
            <div className="spot__collapses">
                {collapsesDatas.map((collapse)=>(
                    <Collapse title={collapse[0]} description={collapse[1]} />
                ))}
            </div>
        </div>
    )
}

function Collapse(props) {

    const[isOpen, setIsOpen] = useState(false);
    const isLink = props.title==="Lien de l'annonce" ? true : false
    const decodedDescription = new DOMParser().parseFromString(props.description, "text/html").body.textContent;
    function handleClick() {
        setIsOpen(!isOpen);
    }

    return(
        <div className="collapse">
            <div className="collapse__header">
                <div classname="collapse__header__title" >
                    {props.title}
                </div>
                {isOpen ? (
                    <div className="collapse__header__icon" onClick={handleClick}>
                        <FontAwesomeIcon icon={faHandPointUp} />
                    </div>
                ):(
                    <div className="collapse__header__icon" onClick={handleClick}>
                        <FontAwesomeIcon icon={faHandPointDown} />
                    </div>
                )}
            </div>
            {isOpen &&
                <div className="collapse__description">
                    {isLink ?  (
                        <a href={decodedDescription} target="_blank" rel="noreferrer">Aller voir sur le site airbnb</a>
                    ) : (
                        <div>{decodedDescription}</div>
                    )}
                    
                </div>
            }
        </div>
    )
}


export default Spot;