import Error from "../Error/error";
const { useState } = require("react");
const { useParams } = require("react-router-dom");
const {addRate, modifyRate} = require("../../services/api");


function Rate(props) {
    const options = [];
    const spotId = props.spotId;
    const eventId = useParams().eventId;
    const oldRate = props.rate;
    const [rate, setRate] = useState(oldRate);
    const [showError, setShowError] = useState(null);

    const toggleError = () => {
        setShowError(null);
    }

    for (let i = 0; i <= 10; i++) {
        if (i===oldRate){
            options.push(<option value={i} classname="form__select" selected="selected">{i}</option>)
        } else {
            options.push(<option classname="form__select" value={i}>{i}</option>);
        }
    }
    async function handleRating(e){
        e.preventDefault();
        try {
            if (oldRate===null) {
                const data = await addRate(eventId, spotId, rate);
                window.location.reload();
                if (data.error) {
                    console.log(data.error);
                }
            } else {
                const data = await modifyRate(eventId, spotId, rate);
                window.location.reload();
                if (data.error) {
                    console.log(data.error);
                }
            }
        } catch (error) {
            console.log(error);
            setShowError(error);
        }
    }

    function handleChange(input) {
        const newRate = input.target.value;
        setRate(newRate);
    }

    if (showError !=null ) return <Error errorData={showError} toggleError={toggleError} />
    return (
        <div className="rate">
            <form onSubmit={handleRating} className="rate__form">
                <div className="rate__form__label">
                    Ta note pour ce lieu 
                </div>
                <div className="rate__form__action">
                    <select onChange={handleChange} value={rate}>{options}</select>
                    <input type="submit" value="Je note" className="form__submit" id="submitRate" />
                </div>

                
            </form>
        </div>
    );
}


export default Rate;