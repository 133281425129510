import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEvent, getNumberOfParticipants } from "../../services/api";
import { Link } from "react-router-dom";
import AddSpot from "../../components/AddSpot/addSpot";
import Spots from "../../components/Spots/spots";
import Error from "../../components/Error/error";
import {convertDbDateToDDMMYYYY} from "../../utils/date/dateConversion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFlagCheckered, faUsersRectangle} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/breadcrumb/breadcrumbSlice";
import { setShowMenu } from "../../store/header/header";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Event () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state)=>state.auth.isLoggedIn);

    useEffect(()=>{
        if (!isLoggedIn) {
            navigate('/login');
        }
        dispatch(setBreadcrumb({name: "Revenir à mes évènements", navigateTo: `/events`}))
        dispatch(setShowMenu(false));
    })

    const [event, setEvent] = useState({});
    const eventId = useParams().eventId;
    const [numberOfParticipants, setNumberOfParticipants] = useState();
    const [showError, setShowError] = useState(null);

    const toggleError = () => {
        setShowError(null);
    }
    useEffect(() => {
        async function getOneEvent() {
            try {
                const eventDatas = await getEvent(eventId);
                setEvent(eventDatas)
                const numberOfParticipants = await getNumberOfParticipants(eventId);
                setNumberOfParticipants(numberOfParticipants);
            } catch (err) {
                console.log(err)
                setShowError(err);
            }
        }
        getOneEvent()
    }, [eventId])

    const startDate = new Date(event.date_start);
    const endDate = new Date(event.date_end);
    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
    if (showError !=null ) return <Error errorData={showError} toggleError={toggleError} />
    return (
        <div className="event">
            <h1>{event.title_event}</h1>
            <div className="event__participants">
                <div className="event__participants__icon"><FontAwesomeIcon icon={faUsersRectangle} /></div>
                <div className="event__participants__link">
                    <Link to={"/events/" + eventId + "/participants"}>Voir les {numberOfParticipants} participants</Link>
                </div>
            </div>
            <div className="event__dates">
                <div className="event__dates__start">
                    <div className="event__dates__start__icon"><FontAwesomeIcon icon={faFlagCheckered} /></div>
                    <div className="event__dates__start__text">{convertDbDateToDDMMYYYY(event.date_start)}</div>
                </div>
                <div className="event__dates__end">
                    <div className="event__dates__end__icon"><FontAwesomeIcon icon={faFlagCheckered} /></div>
                    <div className="event__dates__end__text">{convertDbDateToDDMMYYYY(event.date_end)}</div>
                </div>
            </div>
            <Spots diffDays={diffDays} numberOfParticipants={numberOfParticipants} />
            <AddSpot />
        </div>
    )
}


export default Event;