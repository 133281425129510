import Error from "../../components/Error/error";
import { Link } from "react-router-dom";
import { convertDbDateToDDMMYYYY } from "../../utils/date/dateConversion";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumb } from "../../store/breadcrumb/breadcrumbSlice";
import { setShowMenu } from "../../store/header/header";
const { useState, useEffect } = require("react");
const { getEventsCreated, getEventsParticipated } = require("../../services/api");


function Events() {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state)=>state.auth.isLoggedIn);

    useEffect(()=>{
        if (!isLoggedIn) {
            return navigate('/login');
        }
        dispatch(setBreadcrumb({name: "", navigateTo: `/`}))
        dispatch(setShowMenu(false));
    })
    const [eventsCreated, setEventsCreated] = useState([]);
    const [eventsParticipated, setEventsParticipated] = useState([]);
    const [showError, setShowError] = useState(null);
    const [showCreated, setShowCreated] = useState(true);
    const navigate = useNavigate();

    const toggleError = () => {
        setShowError(null);
    }
    useEffect(() => {
        async function getAllEventsCreated() {
            try {
                const events = await getEventsCreated()
                setEventsCreated(events)
            } catch (err) {
                console.log(err)
                setShowError(err);
            }
        }
        getAllEventsCreated()
    }, [])
    useEffect(() => {
        async function getAllEventsParticipated() {
            try {
                const events = await getEventsParticipated()
                setEventsParticipated(events)
            } catch (err) {
                console.log(err)
            }
        }
        getAllEventsParticipated()
    }, [])
    function handleClick() {
        navigate("/addEvent");
    }
    
    if (showError !=null ) return <Error errorData={showError} toggleError={toggleError} />
    return (
        <div className="events">
            <h1>Mes évènements</h1>
            {showCreated ? (
                <div className="events__container">
                    <h2>Vos évènements créés</h2>
                    <div className="events__created">
                        {eventsCreated.map((event) => (
                            <CardEvent event={event} key={event.id_event} />
                        ))}
                    </div>
                </div>
            ) : (
                <div className="events__container">
                    <h2>Vos participations</h2>
                    <div className="events__participated">
                        {eventsParticipated.map((event) => (
                            <CardEvent event={event} key={event.id_event} />
                        ))}
                    </div>
                </div>  
            )}
            {showCreated ? (
                <div onClick={()=>{setShowCreated(false)}} className="events__switch">
                    Voir les évènéments où je participe
                </div>
            ) : (
                <div onClick={()=>{setShowCreated(true)}} className="events__switch">
                    Voir les évènements que j'ai créés
                </div>
            )}
            <div className="form__submit events__addEvent" onClick={handleClick}>Nouvel évènement</div>
        </div>
    )
}

function CardEvent (props) {
    
    const title = props.event.title_event;
    const dateStart = convertDbDateToDDMMYYYY(props.event.date_start);
    const dateEnd = convertDbDateToDDMMYYYY(props.event.date_end);
    const eventId = props.event.id_event;

    return(
        <Link to={`/events/${eventId}`} key={eventId} className="cardEvent__link">
            <div className="cardEvent">
                <div className="cardEvent__title">{title}</div>
                <div className="cardEvent__dates">
                    <div className="cardEvent__dates__start">Du <br /><span className="date">{dateStart}</span></div>
                    <div className="cardEvent__dates__end">au <br /><span className="date">{dateEnd}</span></div>
                </div>
            </div>
        </Link>
    )
}

export default Events;