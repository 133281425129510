import Error from "../Error/error";
import { getSpots } from "../../services/api";
import CardSpot from "../CardSpot/cardSpot";
import { useParams } from "react-router-dom";
const { useState, useEffect } = require("react");


function Spots(props) {

    const[allSpots, setAllSpots] = useState([]);
    const eventId = useParams().eventId;
    const [showError, setShowError] = useState(null);


    const toggleError = () => {
        setShowError(null);
    }
    useEffect(() => {
        async function getAllSpots() {
            try {
                const spots = await getSpots(eventId);
                setAllSpots(spots);
            } catch (err) {
                console.log(err);
            }
        }
        getAllSpots()
    }, [eventId])

    if (showError !=null ) return <Error errorData={showError} toggleError={toggleError} />
    return (
        <div className="spots">
            {allSpots.map((spot)=>(
                <div key={spot.id_spot}>
                    <CardSpot spot={spot} diffDays={props.diffDays} eventId={eventId} numberOfParticipants={props.numberOfParticipants} />
                </div>
            ))}
        </div>
    )
}


export default Spots;