import Error from "../../components/Error/error";
import { useState } from "react";
import {signUp, addEvent, login} from '../../services/api';
import { useSelector, useDispatch } from 'react-redux';
import { setEvent } from '../../store/event/event';
import { loginReducer, setUserName, setUserId } from '../../store/auth/authSlice';
import { useNavigate } from "react-router-dom";
import { setShowMenu } from '../../store/header/header';
import { useEffect } from 'react';
import { setBreadcrumb } from "../../store/breadcrumb/breadcrumbSlice";


function SignUp() {

    const event = useSelector((state)=>state.event);
    const[user, setUser] = useState({
        email: "",
        password: "",
        name: "",
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formSent, setFormSent] = useState(false);
    const [mailError, setMailError] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [showError, setShowError] = useState(null);

    const toggleError = () => {
        setShowError(null);
    }

    useEffect(()=>{
        dispatch(setShowMenu(false));
        dispatch(setBreadcrumb({name: "", navigateTo: `/`}))
    })
    
    async function handleSignUp(e) {
        e.preventDefault();
        try {
            const emailRegex = /^\S+@\S+\.\S+$/;
            const isValidEmail = emailRegex.test(user.email);
            if (!isValidEmail) {
                setMailError("Adresse mail invalide");
                return;
            } else if (user.name.length < 3 || user.name.length > 20){
                setNameError("Le pseudo doit contenir entre 3 et 20 caractères");
                return;
            } else {
                const data = await signUp(user);
                if (data.error) {
                    console.log(data.error);
                } else {
                    const userLogged = await login(user);
                    if (userLogged.error) {
                        console.log(userLogged.error);
                    } else {
                        dispatch(loginReducer());
                        dispatch(setUserName(userLogged.userName));
                        dispatch(setUserId(userLogged.userId))
                        setFormSent(true);
                        await handleAddEvent();
                        navigate('/events')
                    }
                }
            }
        } catch (err) {
            console.log(err);
            setShowError(err);
        }
    }
    function handleChange(input) {
        const {name, value} = input.target;
        setUser(user=>{
            return {
                ...user,
                [name]: value
            }
        })
    }
    async function handleAddEvent() {
        if (event!=null) {
            try {
                const data = await addEvent(event);
                if (data.error) {
                    console.log(data.error);
                }
                else {
                    dispatch(setEvent(null));
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    if (showError !=null ) return <Error errorData={showError} toggleError={toggleError} />
    return (
        <div className="signup">
            {formSent ? (
                <div>
                    <p>Le compte a bien été créé</p>
                </div>
            ) : (
                <form onSubmit={handleSignUp}>
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" onChange={handleChange} value={user.email} />
                    <label htmlFor="name">Nom</label>
                    <input type="name" id="name" name="name" onChange={handleChange} value={user.name} />
                    <label htmlFor="password">Mot de passe</label>
                    <input type="password" id="password" name="password" onChange={handleChange} value={user.password} />
                    {mailError!=null && 
                        <div className="mail__error">
                            {mailError}
                        </div>
                    }
                    {nameError!=null && 
                        <div className="name__error">
                            {nameError}
                        </div>
                    }
                    <input type="submit" value="S'inscrire" className="form__submit" />
                </form>
            )}
        </div>
    )
}


export default SignUp