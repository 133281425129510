import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    showMenu: false
};

const headerSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        setShowMenu(state, action) {
            state.showMenu = action.payload;
        }
    }
});

export const {setShowMenu} = headerSlice.actions;

export default headerSlice.reducer;