import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    isLoggedIn: false,
    userName: null,
    userId: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginReducer(state) {
            state.isLoggedIn = true;
        },
        logoutReducer(state) {
            state.isLoggedIn = false;
            state.userId = null;
            state.userName = null;
        },
        setUserName(state, action) {
            state.userName = action.payload;
        },
        setUserId(state, action) {
            state.userId = action.payload;
        }
    },
});


export const { loginReducer, logoutReducer, setUserName, setUserId } = authSlice.actions;

export default authSlice.reducer;