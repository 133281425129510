import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import breadcrumbReducer from "./breadcrumb/breadcrumbSlice";
import eventReducer from "./event/event";
import headerReducer from "./header/header";
import localStorageMiddleware from "./middleware/localStorage";
import thunk from "redux-thunk";


const persistedState = localStorage.getItem('reduxState')
    ? JSON.parse(localStorage.getItem('reduxState'))
    : undefined;

const store = configureStore({
    reducer: {
        auth: authReducer,
        breadcrumb: breadcrumbReducer,
        event: eventReducer,
        header: headerReducer,
    },
    preloadedState: persistedState,
    middleware: [thunk, localStorageMiddleware],
});


export default store;