const fullURL = "https://beber-back.onrender.com";

const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
}

function errorHandler(error) {
    let response = {}
    switch(error) {
        case "Requête non authentifiée":
            response.messageToShow = "Merci de vous reconnecter";
            response.redirect = "/login";
            response.logout = true;
            break;
        case "L'utilisateur n'a pas été trouvé":
            response.messageToShow = "Nom d'utilisateur inconnu";
            response.redirect=null;
            break;
        case "L'utilisateur ne participe pas à l'évènement":
            response.messageToShow = "Vous ne parcticipez pas à cet évènement";
            response.redirect = "/events";
            break;
        case "Opération non autorisée":
            response.messageToShow = "Vous n'avez pas les droits pour cette action";
            response.redirect = null;
            break;
        case "Combinaison login/mot de passe incorrecte":
            response.messageToShow = "Combinaison login/mot de passe incorrecte";
            response.redirect = null;
            break;
        case "Un champ est vide":
            response.messageToShow = "Veuillez remplir tous les champs";
            response.redirect = null;
            break;
        case "Date non valide":
            response.messageToShow = "Le format de la date n'est pas valide";
            response.redirect = null;
            break;
        case "Pseudo non valide":
            response.messageToShow = "Le pseudo doit faire entre 3 et 20 caractères";
            response.redirect = null;
            break;
        case "Adresse mail non valide":
            response.messageToShow = "L'adresse mail n'est pas valide";
            response.redirect = null;
            break;
        case "Un compte existe déjà avec cette adresse mail":
            response.messageToShow = "Cette adresse mail a déjà un compte";
            response.redirect = null;
            break;
        case "Un compte existe déjà avec ce pseudo":
            response.messageToShow = "Ce pseudo est déjà pris";
            response.redirect = null;
            break;
        case "Erreur lors de la récupération des données":
            response.messageToShow = "Erreur lors de la récupération des données";
            response.redirect = null;
            break;
        default :
            response.messageToShow = "Une erreur s'est produite";
            response.error = error;
            response.redirect = null;
            break;
        }
    throw response;
}

async function ping() {
    try {
        const response = await fetch(fullURL + '/api/ping', {headers})
        const data = await response.json();
        if (data.error) throw data.error;
        console.log(data);
        if (data.ping !== "pong") {
            console.log("Backend injoignable")
        }
    } catch (error) {
        errorHandler(error);
    }
}
function setToken(token) {
    headers.Authorization = 'Bearer ' + token;
    localStorage.setItem('token', token);
}

async function login(userDatas) {
    try {
        const response = await fetch(fullURL + '/api/auth/login', {
        method: "POST",
        body: JSON.stringify(userDatas),
        headers
    })
        const data = await response.json();
        if (data.error) throw data.error;
        setToken(data.token);
        return data;
    } catch (error) {
        errorHandler(error);
    }
}

async function signUp(userDatas) {
    try {
        const response = await fetch(fullURL + '/api/auth/signup', {
        method: "POST",
        body: JSON.stringify(userDatas),
        headers,
        })
        const data = response.json();
        if (data.error) throw data.error;
        return data;
    } catch (error) {
        errorHandler(error);
    }
}

async function getEventsCreated() {
    try {
        const response = await fetch(fullURL + '/api/events/created', {headers})
        const eventsCreated = await response.json();
        if (eventsCreated.error) throw eventsCreated.error;
        return eventsCreated;
    } catch (error) {
        errorHandler(error);
    }
}

async function getEventsParticipated() {
    try {
        const response = await fetch(fullURL + '/api/events/participated', {headers})
        const eventsParticipated = await response.json();
        if (eventsParticipated.error) throw eventsParticipated.error;
        return eventsParticipated;
    } catch (error) {
        errorHandler(error);
    }

}

async function getParticipants(eventId) {
    try {
        const response = await fetch(fullURL + '/api/events/' + eventId + '/participants', {headers});
        const users = await response.json();
        if (users.error) throw users.error;
        return users;
    } catch (error) {
        errorHandler(error);
    }
}

async function getNumberOfParticipants(eventId) {
    try {
        const response = await fetch(fullURL + '/api/events/' + eventId + '/numberOfParticipants', {headers});
        const users = await response.json();
        if (users.error) throw users.error;
        return users;
    } catch (error) {
        errorHandler(error);
    }
}

async function getEvent(eventId) {
    try {
        const response = await fetch(fullURL + '/api/events/' + eventId, {headers})
        const event =await response.json();
        if (event.error) throw event.error;
        return event;
    } catch (error) {
        errorHandler(error);
    }
}

async function addEvent(eventDatas) {
    try {
        const response = await fetch(fullURL + '/api/events/', {
            method: "POST",
            body: JSON.stringify(eventDatas),
            headers
        })
        const data = await response.json();
        if (data.error) throw data.error;
        return data;
    } catch (error) {
        errorHandler(error);
    }
}

async function modifyEvent(eventId, event) {
    try {
        const response = await fetch(fullURL + '/api/events/' + eventId, {
            method: "PUT",
            body: JSON.stringify(event),
            headers
        })
        const data = await response.json();
        if (data.error) throw data.error;
        return data;
    } catch (error) {
        errorHandler(error);
    }
}

async function deleteEvent(eventId) {
    try {
        const response = await fetch(fullURL + '/api/events/' + eventId, {
            method: "DELETE",
            headers
        })
        const data = response.json();
        if (data.error) throw data.error;
    } catch (error) {
        errorHandler(error);
    }
}

async function inviteUser(eventId, user) {
    try {
        const response = await fetch(fullURL + '/api/events/' + eventId, {
            method: "POST",
            body: JSON.stringify(user),
            headers
        })
        const data = await response.json();
        if (data.error) throw data.error;
        return data;
    } catch (error) {
        errorHandler(error);
    }
}

async function getSpot(eventId, spotId) {
    try {
        const response = await fetch(fullURL + "/api/spots/" + eventId + "/" + spotId, {headers})
        const spot = await response.json();
        if (spot.error) throw spot.error;
        return spot;
    } catch (error) {
        errorHandler(error);
    }
}

async function getImages(eventId, spotId) {
    try {
        const response = await fetch(fullURL + "/api/images/" + eventId + "/" + spotId, {headers});
        const images = await response.json();
        if (images.error) throw images.error;
        return images;
    } catch (error) {
        errorHandler(error);
    }
}

async function getSpots(eventId) {
    try {
        const response = await fetch(fullURL + '/api/spots/' + eventId, {headers})
        const spots = await response.json();
        if (spots.error) throw spots.error;
        return spots;
    } catch (error) {
        errorHandler(error);
    }
}

async function getSpotCreatorName(eventId, spotId) {
    try {
        const response = await fetch(fullURL + '/api/spots/' + eventId + '/' + spotId + '/creator', {headers});
        const creatorName = response.json();
        if (creatorName.error) throw creatorName.error;
        return creatorName;
    } catch (error) {
        errorHandler(error);
    }
}

async function addSpot(spotDatas, eventId) {
    try {
        const response = await fetch(fullURL + '/api/spots/' + eventId, {
            method: "POST",
            body: JSON.stringify(spotDatas),
            headers
        })
        const data = await response.json();
        if (data.error) throw data.error;
        return data;
    } catch (error) {
        errorHandler(error);
    }
}

async function modifySpot(spotId, spot) {
    try {
        const response = await fetch(fullURL + '/api/spots/' + spotId, {
            method: "PUT",
            body: JSON.stringify(spot),
            headers
        })
        const data = await response.json();
        if (data.error) throw data.error;
        return data;
    } catch (error) {
        errorHandler(error);
    }
}

async function deleteSpot(spotId) {
    try {
        const response = fetch(fullURL + '/api/spots/' + spotId, {
            method: "DELETE",
            headers
        })
        const data = response.json();
        if (data.error) throw data.error;
    } catch (error) {
        errorHandler(error);
    }
}

async function getCommentsEvent() {
    try {
        const response = await fetch(fullURL + '/api/comments/event', {headers})
        const commentsEvent = await response.json();
        if (commentsEvent.error) throw commentsEvent.error;
        return commentsEvent;
    } catch (error) {
        errorHandler(error);
    }
}

async function getCommentsSpot() {
    try {
        const response = await fetch(fullURL + '/api/comments/spot', {headers})
        const commentsSpot = await response.json();
        if (commentsSpot.error) throw commentsSpot.error;
        return commentsSpot;
    } catch (error) {
        errorHandler(error);
    }
}

async function addComment(event) {
    try {
        const response = await fetch(fullURL + '/api/events/', {
            method: "POST",
            body: event,
            headers: {
                'Accept': '*/*',
                'Authorization': headers.Authorization
            }
        })
        const data = await response.json();
        if (data.error) throw data.error;
        return data;
    } catch (error) {
        errorHandler(error);
    }
}

async function modifyComment(commentId, comment) {
    try {
        const response = await fetch(fullURL + '/api/comments/' + commentId, {
            method: "PUT",
            body: comment,
            headers: {
                'Accept': "*/*",
                'Authorization': headers.Authorization
            }
        })
        const data = await response.json();
        if (data.error) throw data.error;
        return data;
    } catch (error) {
        errorHandler(error);
    }
}

async function deleteComment(commentId) {
    try {
        const response = await fetch(fullURL + '/api/comments/' + commentId, {
            method: "DELETE",
            headers
        })
        const data = await response.json();
        if (data.error) throw data.error;
    } catch (error) {
        errorHandler(error);
    }
}

async function addRate(eventId, spotId, rate) {
    try {
        const response = await fetch(fullURL + '/api/rates/' + eventId + '/' + spotId, {
            method: "POST",
            body: JSON.stringify({rate}),
            headers
        })
        const data = await response.json();
        if (data.error) throw data.error;
        return data;
    } catch (error) {
        errorHandler(error);
    }
}

async function modifyRate(eventId, spotId, rate) {
    try {
        const response = await fetch(fullURL + '/api/rates/' + eventId + '/' + spotId, {
            method: "PUT",
            body: JSON.stringify({rate}),
            headers
        })
        const data = await response.json();
        if (data.error) throw data.error;
        return data;
    } catch (error) {
        errorHandler(error);
    }
}

async function getAverageRate(eventId, spotId) {
    try {
        const response = await fetch(fullURL + '/api/rates/' + eventId + '/' + spotId, {
            method: "GET",
            headers
        })
        const averageRate = await response.json();
        if (averageRate && averageRate.error) {
            throw averageRate.error;
        }
        return averageRate || null;        
    } catch (error) {
        errorHandler(error);
    }
}


export  {
    ping,
    login,
    signUp,
    getEventsCreated,
    getEventsParticipated,
    getEvent,
    getParticipants,
    getNumberOfParticipants,
    addEvent,
    modifyEvent,
    deleteEvent,
    inviteUser,
    getSpot,
    getSpots,
    getSpotCreatorName,
    getImages,
    addSpot,
    modifySpot,
    deleteSpot,
    getCommentsEvent,
    getCommentsSpot,
    addComment,
    modifyComment,
    deleteComment,
    addRate,
    modifyRate,
    getAverageRate,
}