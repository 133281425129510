import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faStar, faUser, faLocationCrosshairs, faDollarSign} from "@fortawesome/free-solid-svg-icons";
import { Link} from "react-router-dom";
import { useState, useEffect } from "react";
import { getAverageRate, getSpotCreatorName } from "../../services/api";
import Error from "../Error/error";


function CardSpot(props) {

    const spot = props.spot;
    const decodedTitle = new DOMParser().parseFromString(spot.title_spot, "text/html").body.textContent;
    const decodedLocation = new DOMParser().parseFromString(spot.location, "text/html").body.textContent;
    const eventId = props.eventId
    const spotId = spot.id_spot;
    const diffDays = props.diffDays;
    const price = parseInt(spot.price);
    const numberOfParticipants = props.numberOfParticipants;
    const pricePerPerson = Math.floor(price/numberOfParticipants);
    const [averageRate, setAverageRate] = useState(null);
    const [creatorName, setCreatorName] = useState("");
    const [showError, setShowError] = useState(null);

    const toggleError = () => {
        setShowError(null);
    }
    useEffect(() => {
        async function getAverage() {
            try {
                const rate = await getAverageRate(eventId, spotId);
                setAverageRate(rate);
            } catch (err) {
                setShowError(err);
            }
        }
        getAverage()
    }, [eventId, spotId])

    useEffect(() => {
        async function getCreatorName() {
            try {
                const creator = await getSpotCreatorName(eventId, spotId);
                setCreatorName(creator);
            } catch (err) {
                setShowError(err);
            }
        }
        getCreatorName()
    }, [eventId, spotId])

    if (decodedTitle==='null') return ;
    
    if (showError !=null ) return <Error errorData={showError} toggleError={toggleError} />
    return(
        <Link to={`/events/${eventId}/${spotId}?pricePerPerson=${pricePerPerson}&diffDays=${diffDays}`} className="cardLink"
            decodedTitle={decodedTitle}
            decodedLocation={decodedLocation}
        >
            <div className="cardSpot">
                <div className="cardSpot__header">
                    <div className="cardSpot__header__title">{decodedTitle}</div>
                    <div className="cardSpot__header__rate">
                        <div className="cardSpot__header__rate__icon">
                            <FontAwesomeIcon icon={faStar} />
                        </div>
                        <div className="cardSpot__header__rate__text">
                            {averageRate}/10
                        </div>
                    </div>
                </div>
                <div className="cardSpot__image">
                    <img src={spot.image_url} alt="cardImage" />
                </div>
                <div className="cardSpot__location">
                    <div className="cardSpot__location__icon">
                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                    </div>
                    <div className="cardSpot__location__text">
                        {decodedLocation}
                    </div>
                </div>
                {(!price) ? (
                    <div className="cardSpot__indisponible">
                        Lieu indisponible aux dates
                    </div>
                    ):(
                    <div className="cardSpot__price">
                        <div className="cardSpot__price__icon">
                            <FontAwesomeIcon icon={faDollarSign} />
                        </div>
                            <div className="cardSpot__price__text">
                                <div className="cardSpot__price__text__total">
                                    {price}€/{diffDays}jours
                                </div>
                                <div className="cardSpot__price__text__perPerson">
                                    {pricePerPerson}€/personne 
                                </div>
                            </div>
                    </div>
                    )}
                <div className="cardSpot__user">
                    <div className="cardSpot__user__icon">
                        <FontAwesomeIcon icon={faUser} />
                    </div>
                    <div className="cardSpot__user__text">
                        proposé par {creatorName}
                    </div>
                </div>
                <div className="cardSpot__more">Voir plus</div>
            </div>
        </Link>
    )
}


export default CardSpot;