import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";

function Breadcrumb() {
    
    const breadcrumb = useSelector(state=>state.breadcrumb.breadcrumb);
    const navigate = useNavigate();
    function handleClick() {
        if (breadcrumb===null) {
            return;
        } else {
            navigate(breadcrumb.navigateTo)
        }
    }
    return (
        <>
            {breadcrumb!=null && 
                <div className="breadcrumb" onClick={handleClick}>
                    {breadcrumb.name}
                </div>
            }
        </>
    )
}


export default Breadcrumb;