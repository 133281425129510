import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutReducer } from "../../store/auth/authSlice";
import { setShowMenu } from "../../store/header/header";


function Error(props) {

    const {messageToShow, redirect, logout, error} = props.errorData;
    if (error) {
        console.log(error);
    }
    const {toggleError} = props;
    const navigate = useNavigate();
    const dispatch= useDispatch();

    if (logout) {
        dispatch(logoutReducer);
    }
    useEffect(()=>{
        dispatch(setShowMenu(false));
    })
    const handleClick = () => {
        if (redirect===null) {
            toggleError();
        } else {
            toggleError();
            navigate(redirect);
        }
    }

    return(
        <div className="error">
            <div className="error__message">
                {messageToShow}
            </div>
            <div className="error__button">
                <button onClick={handleClick}>Ok</button>
            </div>
        </div>
    )
}


export default Error;