import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { logoutReducer } from '../../store/auth/authSlice';
import Breadcrumb from '../Breadcrumb/breadcrumb';
import { setShowMenu } from '../../store/header/header';
const headerAccount = require("../../assets/headerAccount.png");

function Header() {

    const showMenu = useSelector((state)=>state.header.showMenu);
    const dispatch = useDispatch();
    const useToggleMenu = () => {
        dispatch(setShowMenu(!showMenu));
    }

    return(
        <header className="header">
            <Breadcrumb className="header__breadcrumb" />
            <div className='header__account'>
                <img src={headerAccount} alt="account" onClick={useToggleMenu} />
            </div>
            {showMenu &&
                <Nav />
            }
        </header>
    )
}

function Nav() {

    const isLoggedIn = useSelector((state)=>state.auth.isLoggedIn);
    const userName = useSelector((state)=>state.auth.userName);
    const dispatch = useDispatch();

    const disconnect = () => {
        try {
            localStorage.clear();
            dispatch(logoutReducer());
        }
        catch (err) {
            console.log(err)
        }
    }

    return(
        <div>
            {isLoggedIn ?
                <nav>
                    <span>Bonjour {userName}</span>
                    <Link to={"/addEvent"} className="header__link">Créer un évènement</Link>
                    <Link to={"/events"} className="header__link">Mes évènements</Link>
                    <Link to={"/login"} className="header__link" onClick={disconnect}>Se déconnecter</Link>
                </nav>
                :
                <nav>
                    <Link to={"/login"} className="header__link">Se connecter</Link>
                    <Link to={"/signup"} className="header__link">S'inscrire</Link>
                </nav>
            }
        </div>
    )
}

export default Header