import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
import Header from "../Header/header"
import Landing from "../../pages/Landing/landing"
import SignUp from "../../pages/SignUp/signup"
import Login from "../../pages/Login/login"
import AddEvent from "../../pages/AddEvent/addEvent"
import Events from "../../pages/Events/events"
import Event from "../../pages/Event/event"
import Spot from "../../pages/Spot/spot"
import Participants from "../../pages/Participants/participants"


function AppRouter () {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path="/events" element={<Events />} />
                <Route path="/addEvent" element={<AddEvent />} />
                <Route path="/events/:eventId" element={<Event />} />
                <Route path="/events/:eventId/participants" element={<Participants />} />
                <Route path="/events/:eventId/:spotId" element={<Spot />} />
            </Routes>
        </Router>
    )
}


export default AppRouter