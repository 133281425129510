import {useSelector, useDispatch} from "react-redux"; 
import { setEvent } from "../../store/event/event";
import SignUp from "../SignUp/signup";
import Login from "../Login/login";
import Error from "../../components/Error/error";
import { setBreadcrumb } from "../../store/breadcrumb/breadcrumbSlice";
import { setShowMenu } from "../../store/header/header";
import { useNavigate } from "react-router-dom";
const { useState, useEffect } = require("react");
const { addEvent } = require("../../services/api")


function AddEvent() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const datePlaceHolder = "Au format jj/mm/aaaa";
    const [eventDatas, setEventDatas] = useState({
        title: "",
        dateStart: "",
        dateEnd: ""
    })
    const [formSent, setFormSent] = useState(false);
    const [showConnectionForm, setShowConnectionForm] = useState(false);
    const [dateError, setDateError] = useState(null);
    const [eventError, setEventError] = useState(null);
    const [showError, setShowError] = useState(null);
    
    const toggleError = () => {
        setShowError(null);
    }
    const isLoggedIn = useSelector((state)=>state.auth.isLoggedIn);
    
    useEffect(()=>{
        dispatch(setShowMenu(false));
        let breadcrumbToShow = {name: "Revenir à mes évènements", navigateTo: `/events` }
        if (!isLoggedIn) {
            breadcrumbToShow = {name: "", navigateTo: `/` };
        }
        dispatch(setBreadcrumb(breadcrumbToShow));
    }, [isLoggedIn, dispatch]);

    async function handleEventCreation(e) {
        e.preventDefault();
        if (!eventDatas.dateStart || !eventDatas.dateEnd) {
            setDateError("Veuillez saisir une date de début et une date de fin pour l'évènement.");
        }
        if (eventDatas.title.length < 3 || eventDatas.title.length > 100) {
            setEventError("Le titre de l'évènement doit contenir entre 3 et 100 caractères");
            return;
        }
        console.log(isLoggedIn)
        if (isLoggedIn) {
            try {
                const event = {event: eventDatas};
                console.log("event", event)
                const data = await addEvent(event);
                console.log(data);
                if (data.error) {
                    console.log(data.error);
                } else {
                    console.log("test")
                    navigate('/events')
                }
            } catch (err) {
                console.log(err);
                setShowError(err);
            }
        } else {
            dispatch(setEvent(eventDatas));
            setFormSent(true);
            setShowConnectionForm(true);
        }
    }

    function handleChange(input) {
        const {name, value} = input.target;
        if (name === "dateStart" || name === "dateEnd") {
            input.target.type = "date"; 
        }
        setEventDatas(eventDatas=>{
            return {
                ...eventDatas,
                [name]: value
            }
        })
    }

    if (showError !=null ) return <Error errorData={showError} toggleError={toggleError} />

    return(
        <div>
            {formSent ? (
                <div>
                    {!showConnectionForm ? (
                        <div>Evènement ajouté</div>
                    ) : (
                        <AccountForm />
                    )}
                </div>
            ) : (
                <div>
                    <h2 className="form__title">Crée ton évènement</h2>
                    <form onSubmit={handleEventCreation}>
                        <label htmlFor="title">Comment s'appelle ton évènement ? </label>
                        <input 
                            type="text" 
                            id="title" 
                            name="title" 
                            onChange={handleChange} 
                            value={eventDatas.title} 
                            placeholder="Ex : Week-end au ski" 
                        />
                        <label htmlFor="dateStart">Quand commence-t-il ?</label>
                        <input 
                            type="text" 
                            id="dateStart" 
                            name="dateStart" 
                            onChange={handleChange} 
                            value={eventDatas.dateStart} 
                            placeholder={datePlaceHolder}
                            className="form__date" 
                            onFocus={handleChange} 
                        />
                        <label htmlFor="dateEnd">Quand se termine-t-il ?</label>
                        <input 
                            type="text" 
                            id="dateEnd" name="dateEnd" 
                            onChange={handleChange} 
                            value={eventDatas.dateEnd} 
                            placeholder={datePlaceHolder} 
                            className="form__date" 
                            onFocus={handleChange} 
                        />
                        {eventError!=null && 
                            <div className="event__error">
                                {eventError}
                            </div>
                        }
                        {dateError!=null && 
                            <div className="dates__error">
                                {dateError}
                            </div>
                        }
                        <input type="submit" value="Ok" className="form__submit"/>
                    </form>
                </div>
            )}
        </div>
    )
}

function AccountForm() {

    const [showLoginForm, setShowLoginForm] = useState(false);

    function handleChange (){
        setShowLoginForm(!showLoginForm);
    }
    return(
        <div>
            <div className="accountForm__text">Ton évènement a bien été enregistré. Crée un compte ou connecte toi pour inviter tes amis </div>
            {!showLoginForm ? (
                <div>
                    <SignUp />
                    <div className="switchForm">
                        Tu as déjà un compte ? <br /><span className="switchForm__link" onClick={handleChange}>Connecte-toi</span>
                    </div>
                </div>
            ) : ( 
                <div>
                    <Login />
                    <div className="switchForm">
                        Tu n'es pas encore de compe ? <br /><span className="switchForm__link" onClick={handleChange}>Inscris toi</span>
                    </div>
                </div>
            )}
        </div>
    )
}



export default AddEvent