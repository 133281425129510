import Error from "../../components/Error/error";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getParticipants, inviteUser } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcrumb } from "../../store/breadcrumb/breadcrumbSlice";
import { setShowMenu } from "../../store/header/header";


function Participants () {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state)=>state.auth.isLoggedIn);
    const [participants, setParticipants] = useState([]);
    const [creator, setCreator] = useState({});
    const [isCreator, setIsCreator] = useState(false);
    const eventId = useParams().eventId;
    const userId = useSelector((state)=>state.auth.userId);
    const [showError, setShowError] = useState(null);

    const toggleError = () => {
        setShowError(null);
    }
    useEffect(()=>{
        if (!isLoggedIn) {
            navigate('/login');
        }
        dispatch(setBreadcrumb({name: "Revenir à l'évènement", navigateTo: `/events/${eventId}`}))
        dispatch(setShowMenu(false));
    })

    const getListOfParticipants = useCallback(async () => {
        try {
            const users = await getParticipants(eventId);
            setParticipants(users.participants);
            setCreator(users.creator);
            if (creator.id_user===userId) {
                setIsCreator(true);
            }
        } catch (err) {
            console.log(err);
            setShowError(err);
        }
    }, [eventId, userId, creator.id_user]);

    useEffect(()=> {
        async function updateParticipants() {
            try {
                await getListOfParticipants();
            } catch (err) {
                console.log(err);
            }
        }
        updateParticipants() 
    }, [eventId, getListOfParticipants])
        
    
    if (showError !=null ) return <Error errorData={showError} toggleError={toggleError} />
    return(
        <div className="participants">
            <h1 className="participants__title">Les Participants</h1>
            <div className="participants__creator">
                <div className="participants__creator__label">Organisateur </div>
                <div className="participants__creator__name">{creator.name}</div>
            </div>
            <div className="participants__invited">
                <h2>Tous les participants</h2>
                <div className="participants__container">
                    {participants.map((participant) => (
                        <div className="participant__invited__name" key={participant.id_user}>
                            {participant.name}
                        </div>
                    ))}
                </div>
            </div>
            {isCreator && 
                <Invite getListOfParticipants={getListOfParticipants} />
            }
        </div>
    )
}

function Invite(props) {

    const [user, setUser] = useState({name: ""});
    const [showError, setShowError] = useState(null);
    const eventId = useParams().eventId;
    const [nameError, setNameError] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const toggleError = () => {
        setShowError(null);
    }

    function handleChange(input) {
        setUser({name: input.target.value});
    }

    async function handleInvitation(e) {
        e.preventDefault();
        if (user.name.length < 3 || user.name.length > 20) {
            setNameError("Le pseudo doit contenir entre 3 et 20 caractères");
            return;
        }
        try {
            const data = await inviteUser(eventId, user);
            if (data.error) {
                console.log(data.error);
            } else {
                props.getListOfParticipants();
                setRefresh(!refresh);
            }
        } catch (err) {
            console.log(err);
            setShowError(err);
        }
    }

    if (showError !=null ) return <Error errorData={showError} toggleError={toggleError} />
    return(
        <form onSubmit={handleInvitation} className="participants__form">
            <label htmlFor="name">Entre le pseudo de ton ami</label>
            <input type="text" id="name" name="name" onChange={handleChange} value={user.name} placeholder="Ex : Caracole"/>
            {nameError!=null && 
                <div className="name__error">
                    {nameError}
                </div>
            }
            <input type="submit" value="Inviter" className="form__submit addName"/>
        </form>
    )
}

export default Participants;