import { useEffect } from "react";
import { setShowMenu } from "../../store/header/header";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/breadcrumb/breadcrumbSlice";
const landingLetter = require ("../../assets/landingLetter.png");
const landingHouse = require ("../../assets/landingHouse.png");
const landingSun = require ("../../assets/landingSun.png");
const landingFlamingo = require ("../../assets/landingFlamingo.png");
const landingFooter = require ("../../assets/landingFooter.png");
const phoneMode = require("../../assets/phoneMode.png")


function Landing () {


    const cardsActionDatas = [
        {
            imgSrc: landingLetter,
            imgAlt: "letter",
            text: "Crée un évènement et invite tes amis"
        },
        {
            imgSrc: landingHouse,
            imgAlt: "house",
            text: "Ajoute les logements qui te plaisent"
        },
        {
            imgSrc: landingSun,
            imgAlt: "sun",
            text: "Comparez, votez et décidez où vous partez !"
        }
    ];
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(setShowMenu(false));
        dispatch(setBreadcrumb({name: "", navigateTo: `/`}))
    })
    const handleClick = () => {
        document.getElementById("imageToAnimate").classList.add("animation")
        document.getElementById("textToAnimate").classList.add("animation")
        setTimeout(function() {
            window.location.href = '/addEvent';
        }, 1000);
    } 
    function handleReload() {
        console.log("===")
        console.log(window.innerWidth)
        window.location.reload();
    }
    if (window.innerWidth > 500) {
        return (
            <div className="landing__phone">
                <h1>BEBER</h1>
                <h2>Bienvenue sur la version 0.1</h2>
                <div className="landing__phone__text">
                    L'application est en cours de développement et n'est pas encore responsive. 
                    Pour tester l'application, il est nécessaire de passer votre navigateur en mode téléphone. 
                    Ouvrez l'inspecteur (Ctrl+Shift+C sous Windows) et sélectionnez l'icône du mode téléphone
                </div>
                <div className="landing__phone__image">
                    <img src={phoneMode} alt="phoneMode" />
                </div>
                <div className="form__submit" onClick={handleReload}>Je suis prêt</div>
            </div>
        )
    }
    return(
        <div className="landing">
            <h1>BEBER</h1>
            <h2>Le meilleur <span className="landing__specialWord">accompagnateur</span> pour un séjour entre amis</h2>
            <div className="landing__cardsContainer">
                {cardsActionDatas.map((datas) => (
                    <CardAction imgSrc={datas.imgSrc} imgAlt={datas.imgAlt} text={datas.text} key={datas.text} />
                ))}
            </div>
            <div className="landing__button" onClick={handleClick}>
                <div className="landing__button__image" id="imageToAnimate">
                    <img src={landingFlamingo} alt="flamingo" />
                </div>
                <div className="landing__button__text" id="textToAnimate">
                    C'est parti !
                </div>
            </div>   
            <div className="landing__footer">
                <img src={landingFooter} alt="house" />
            </div>    
        </div>
    )
}

function CardAction (props) {
    return(
        <div className="cardAction">
            <div className="cardAction__image">
                <img src={props.imgSrc} alt={props.imgAlt} />
            </div>
            <div className="cardAction__text">{props.text}</div>
        </div>
    )
}


export default Landing;