function convertDbDateToYYYYMMDD(dateToConvert) {
    const date = new Date(dateToConvert);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return`${year}-${month}-${day}`;
}

function convertDbDateToDDMMYYYY(dateToConvert) {
    const date = new Date(dateToConvert);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return`${day}-${month}-${year}`;
}

module.exports = { convertDbDateToDDMMYYYY, convertDbDateToYYYYMMDD }
