import { login, addEvent} from '../../services/api';
import Error from "../../components/Error/error";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import {useNavigate} from "react-router-dom"
import { loginReducer, setUserName, setUserId } from '../../store/auth/authSlice';
import { setEvent } from '../../store/event/event';
import { setShowMenu } from '../../store/header/header';
import { setBreadcrumb } from "../../store/breadcrumb/breadcrumbSlice";
const { useState } = require("react");


function Login() {

    const isLoggedIn = useSelector((state)=>state.auth.isLoggedIn);
    const event = useSelector((state)=>state.event);
    const[user, setUser] = useState({
        email: "",
        password: "",
    })
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [mailError, setMailError] = useState(null);
    const [showError, setShowError] = useState(null);

    const toggleError = () => {
        setShowError(null);
    }

    useEffect(()=>{
        dispatch(setShowMenu(false));
        dispatch(setBreadcrumb({name: "", navigateTo: `/`}))
    })

    async function handleLogin(e) {
        e.preventDefault();
        try {
            const emailRegex = /^\S+@\S+\.\S+$/;
            const isValidEmail = emailRegex.test(user.email);
            if (!isValidEmail) {
                setMailError("Adresse mail invalide");
                return;
            } else {
                const userLogged = await login(user);
                if (userLogged.error) {
                    console.log(userLogged.error);
                } else {
                    dispatch(loginReducer());
                    dispatch(setUserName(userLogged.userName));
                    dispatch(setUserId(userLogged.userId))
                    await handleAddEvent();
                    navigate('/events');
                }
            }
        } catch (err) {
            console.log(err);
            setShowError(err);
        }
    }

    function handleChange(input) {
        const {name, value} = input.target;
        setUser(user=>{
            return {
                ...user,
                [name]: value
            }
        })
    }

    async function handleAddEvent() {
        if (event.event!=null) {
            try {
                const data = await addEvent(event);
                if (data.error) {
                    console.log(data.error);
                }
                else {
                    dispatch(setEvent(null));
                }
            } catch (err) {
                console.log(err);
            }
        }
    }
    
    if (showError !=null ) return <Error errorData={showError} toggleError={toggleError} />
    return(
        <div>
            {isLoggedIn ? (
                <div>
                    <p>Connexion réussie</p>
                </div>
            ) : (
                <form onSubmit={handleLogin}>
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" onChange={handleChange} value={user.email} />
                    <label htmlFor="password">Mot de passe</label>
                    <input type="password" id="password" name="password" onChange={handleChange} value={user.password} />
                    {mailError!=null && 
                        <div className="mail__error">
                            {mailError}
                        </div>
                    }
                    <input type="submit" value="Se connecter" className='form__submit'/>
                </form>
            )}
        </div>
    )
}


export default Login