import Error from "../Error/error";
import { PulseLoader } from "react-spinners";
const { useState } = require("react");
const { useParams } = require("react-router-dom");
const { addSpot } = require("../../services/api")


function AddSpot() {
    const [spotDatas, setSpotDatas] = useState({
        link: "",
        hostLink: ""
    })
    const eventId = useParams().eventId;
    const [linkError, setLinkError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(null);

    const toggleError = () => {
        setShowError(null);
    }

    async function handleSpotCreation(e) {
        e.preventDefault();
        try {
            const { link } =spotDatas;
            if (!link.startsWith("https://www.airbnb.")){
                setLinkError("Veuillez entrer un lien d'annonce airbnb");
                return;
            }
            setIsLoading(true);
            const data = await addSpot(spotDatas, eventId);
            setIsLoading(false);
            if (data.error) {
                console.log(data.error);
            } else {
                window.location.reload();
            }
        } catch (err) {
            console.log(err);
            setShowError(err);
        }
    }

    function handleChange(input) {
        const {name, value} = input.target;
        setSpotDatas(spotDatas=>{
            return {
                ...spotDatas,
                [name]: value
            }
        })
    }
    
    if (showError !=null ) return <Error errorData={showError} toggleError={toggleError} />
    return(
        <div>
            {isLoading ? (
                <div className="loading">
                    <div className="loading__text">Béber traite ton annonce. Elle sera visible d'ici une minute</div>
                    <div className="loading__spinner"><PulseLoader color="#FFE9D0" /></div>
                </div>
            ) : (
                <form onSubmit={handleSpotCreation}>
                    <label htmlFor="link">Une annonce t'a tapé dans l'oeil ? Ajoute là en rentrant son lien !</label>
                    <input type="text" id="link" name="link" onChange={handleChange} value={spotDatas.link} placeholder="Ex: https://www.airbnb.fr/rooms/"/>
                    {linkError!=null && 
                        <div className="link__error">
                            {linkError}
                        </div>
                    }
                    <input type="submit" value="Ajouter l'annonce" className="form__submit addLink"/>
                </form>
            )}
        </div>
    )
}


export default AddSpot